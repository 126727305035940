import './App.css';
import About from './layouts/about/About';
import Contact from './layouts/contact/Contact';
import Header from './layouts/header/Header';
import Navbar from './layouts/navbar/Navbar';
import News from './layouts/news/News';
import Partners from './layouts/partners/Partners';
import Team from './layouts/team/Team';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import Footer from './layouts/footer/Footer';

function App() {

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className="App">
      <section id='home'>
        <Navbar/>
        <Header/>
      </section>
      <div className='container my-5 px-4'>
        <section id='about'>
          <About/>
        </section>
      </div>
        <section id='partners'>
          <Partners/>
        </section>
      <div className='container my-5 px-4'>
        <section id='news'>
          <News/>
        </section>
        <section id='team'>
          <Team/>
        </section>
        <section id='contact'>
          <Contact/>
        </section>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
