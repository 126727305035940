import React, { useRef, useState } from 'react';
import './header.css'
import { AiOutlinePlayCircle } from "react-icons/ai";
import { AiOutlinePauseCircle } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import { BsTiktok } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";

function Header() {

    const videoRef = useRef();
    const [stop, setStop] = useState(false);
    const [buttonText, setButtonText] = useState(<AiOutlinePlayCircle style={{ width: '64px', height: '64px' }} />);

    const handleVideo = () => {
        setStop(!stop);
        if (stop === true) {
            videoRef.current.pause();
            setButtonText(<AiOutlinePlayCircle style={{ width: '64px', height: '64px' }} />);
        } else {
            videoRef.current.play();
            setButtonText(<AiOutlinePauseCircle style={{ width: '64px', height: '64px' }} />);
        }
    };

    return (
        <div>
            <div className='header-wrapper'>
                <video loop className='demo-video overflow-hidden' poster={require('./../../assets/images/poster.jpeg')} ref={videoRef}>
                    <source src={require('./../../assets/video/spaceship.mp4')} type='video/mp4' />
                </video>
                <div className='video-tint'></div>
            </div>

            <div className='d-flex flex-wrap justify-content-center'>
                <div className='container position-absolute header-text-wrapper overflow-hidden'>
                    <div className='header-text' data-aos='fade-right' data-aos-delay='500'><h1>THE UNFORGETTABLE</h1></div>
                    <div className='header-text' data-aos='fade-left' data-aos-delay='800'><h1>EXPERIENCE OF</h1></div>
                    <div className='header-text accent' data-aos='fade-up' data-aos-delay='1200'><h1>SPACE</h1></div>
                    <div className='header-text' data-aos='fade-left' data-aos-delay='1500'><h1>EXPLORATION</h1></div>
                    <div data-aos='fade-left' data-aos-delay='1800'>- 10 immersive adventures of your holiday -</div>
                    <div className='py-3' data-aos='fade-up' data-aos-delay='2100'>
                        <button className='video-button blob green' onClick={handleVideo}>{buttonText}</button>
                    </div>
                </div>
            </div>

            <div className='bottom-header'>
                <div className='header-bottom-background'>
                    <div className='container'>
                        <div className='row overflow-hidden'>
                            <div className='col-sm-12 col-md-4 col-lg-4 text-center' data-aos='fade-up' data-aos-delay='2400'>
                                <div className='d-block my-4'>
                                    <h1 className='d-inline'><BsFacebook /></h1>
                                    <span className='d-inline my-3 px-3 bold'>Facebook</span>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-4 col-lg-4 text-center' data-aos='fade-up' data-aos-delay='2700'>
                                <div className='d-block my-4'>
                                    <h1 className='d-inline'><BsTiktok /></h1>
                                    <span className='d-inline my-3 px-3 bold'>TikTok</span>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-4 col-lg-4 text-center' data-aos='fade-up' data-aos-delay='3000'>
                                <div className='d-block my-4'>
                                    <h1 className='d-inline'><BsInstagram /></h1>
                                    <span className='d-inline my-3 px-3 bold'>Instagram</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header