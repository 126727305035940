import './team.css';
import React from 'react'

function Team() {
    return (
        <div className='my-5'>
            <h1 className='bold my-5'>Our Team</h1>
            <div className='row overflow-hidden'>
                <div className='col-sm-12 col-md-12 col-lg-6 my-4 px-4' data-aos='fade-up'>
                    <div className='row text-center text-md-start text-lg-start'>
                        <div className='col-sm-3 col-md-3 col-lg-3 my-3 my-lg-0'>
                            <img className="team-profile-photo" draggable='false' src={require('./../../assets/images/team/0.jpg')} alt="team_name" />
                        </div>
                        <div className='col-sm-9 col-md-9 col-lg-9'>
                            <div className='team-profile-name'>
                                Jon Snow
                            </div>
                            <div className='team-profile-attribute my-2'>
                                Galactico CEO
                            </div>
                            <div className='team-profile-description small my-2 content-text'>
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illum itaque placeat fugiat modi fugit omnis amet aliquam, rerum dolores quasi!
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-sm-12 col-md-12 col-lg-6 my-4 px-4' data-aos='fade-up'>
                    <div className='row text-center text-md-start text-lg-start'>
                        <div className='col-sm-3 col-md-3 col-lg-3 my-3 my-lg-0'>
                            <img className="team-profile-photo" draggable='false' src={require('./../../assets/images/team/41.jpg')} alt="team_name" />
                        </div>
                        <div className='col-sm-9 col-md-9 col-lg-9'>
                            <div className='team-profile-name'>
                                Tyrion Lannister
                            </div>
                            <div className='team-profile-attribute my-2'>
                                Team Leader
                            </div>
                            <div className='team-profile-description small my-2 content-text'>
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illum itaque placeat fugiat modi fugit omnis amet aliquam, rerum dolores quasi!
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-sm-12 col-md-12 col-lg-6 my-4 px-4' data-aos='fade-up'>
                    <div className='row text-center text-md-start text-lg-start'>
                        <div className='col-sm-3 col-md-3 col-lg-3 my-3 my-lg-0'>
                            <img className="team-profile-photo" draggable='false' src={require('./../../assets/images/team/71.jpg')} alt="team_name" />
                        </div>
                        <div className='col-sm-9 col-md-9 col-lg-9'>
                            <div className='team-profile-name'>
                                Arya Stark
                            </div>
                            <div className='team-profile-attribute my-2'>
                                Analyst
                            </div>
                            <div className='=team-profile-description my-2 content-text'>
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illum itaque placeat fugiat modi fugit omnis amet aliquam, rerum dolores quasi!
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-sm-12 col-md-12 col-lg-6 my-4 px-4' data-aos='fade-up'>
                    <div className='row text-center text-md-start text-lg-start'>
                        <div className='col-sm-3 col-md-3 col-lg-3 my-3 my-lg-0'>
                            <img className="team-profile-photo" draggable='false' src={require('./../../assets/images/team/78.jpg')} alt="team_name" />
                        </div>
                        <div className='col-sm-9 col-md-9 col-lg-9'>
                            <div className='team-profile-name'>
                                Eddard Stark
                            </div>
                            <div className='team-profile-attribute my-2'>
                                Developer
                            </div>
                            <div className='team-profile-description small my-2 content-text'>
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illum itaque placeat fugiat modi fugit omnis amet aliquam, rerum dolores quasi!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team