import './footer.css';
import React from 'react'
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";

function Footer() {
    return (
        <div>
            <div className='row mt-5'>
                <div className='col-sm-12 col-md-12 col-lg-6 mt-5'>
                    <h2>Galactico Immersive Ltd</h2>
                    <div className='my-1 content-text small'>
                        <p className='my-2'>
                            <a href='https://www.google.com/maps/place/71,+75+Shelton+St,+London+WC2H+9JQ,+Regatul+Unit/@51.5148525,-0.123525,17z/data=!3m1!4b1!4m6!3m5!1s0x487604ccab1f237b:0x436d7f0832fd499d!8m2!3d51.5148525!4d-0.123525!16s%2Fg%2F11ll1wzv7n' rel='noreferrer' target='_blank' className='nav-link'>
                                71-75 Shelton Street, Covent Garden, <br /> London, United Kingdom
                            </a>
                            <a href='mailto:galactico.project@gmail.com' className='nav-link my-2'>galactico.project@gmail.com</a>
                        </p>
                    </div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-6 d-flex flex-wrap justify-content-start justify-content-lg-end mt-2 my-lg-5 content-text'>
                    <div className='row'>
                        <div className='col-12 col-md-12 col-lg-2'>
                            <a href="#home" className='nav-link footer-link'>Home</a>
                        </div>
                        <div className='col-12 col-md-12 col-lg-2'>
                            <a href="#about" className='nav-link footer-link my-1 my-lg-0'>About</a>
                        </div>
                        <div className='col-12 col-md-12 col-lg-2'>
                            <a href="#partners" className='nav-link footer-link'>Partners</a>
                        </div>
                        <div className='col-12 col-md-12 col-lg-2'>
                            <a href="#news" className='nav-link footer-link my-1 my-lg-0'>News</a>
                        </div>
                        <div className='col-12 col-md-12 col-lg-2'>
                            <a href="#team" className='nav-link footer-link'>Team</a>
                        </div>
                        <div className='col-12 col-md-12 col-lg-2'>
                            <a href="#contact" className='nav-link footer-link my-1 my-lg-0'>Contact</a>
                        </div>
                    </div>
                </div>

                <div className='col-sm-12 col-md-12 col-lg-6 my-4 small'>
                    <div className='muted'>Copyright @ Galactico {new Date().getFullYear()} - Design by &nbsp;<a href='https://snapdesign.ro' className='footer-link' target='_blank' rel='noreferrer'>SnapDesign</a></div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-6 d-flex flex-wrap justify-content-center justify-content-lg-end content-text'>
                    <a href='https://facebook.com' target='_blank' rel='noreferrer' className='nav-link footer-link px-3'><BsFacebook /></a>
                    <a href='https://twitter.com' target='_blank' rel='noreferrer' className='nav-link footer-link px-3'><BsTwitter /></a>
                    <a href='https://youtube.com' target='_blank' rel='noreferrer' className='nav-link footer-link px-3'><BsYoutube /></a>
                </div>
            </div>
        </div>
    )
}

export default Footer