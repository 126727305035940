import React from 'react'
import './accordion.css';
import Carousel from 'fade-carousel';

function Accordion() {

    const alienForest = [
        require('./../../assets/slides/alien-forest-walk/1.jpg'),
        require('./../../assets/slides/alien-forest-walk/2.jpg'),
        require('./../../assets/slides/alien-forest-walk/3.jpg'),
        require('./../../assets/slides/alien-forest-walk/4.jpg')
    ];

    const cyborg = [
        require('./../../assets/slides/cyborg/1.jpg'),
        require('./../../assets/slides/cyborg/2.jpg'),
        require('./../../assets/slides/cyborg/3.jpg'),
        require('./../../assets/slides/cyborg/4.jpg')
    ];

    const exploringTheMoon = [
        require('./../../assets/slides/exploring-the-moon/1.jpg'),
        require('./../../assets/slides/exploring-the-moon/2.jpg'),
        require('./../../assets/slides/exploring-the-moon/3.jpg'),
        require('./../../assets/slides/exploring-the-moon/4.jpg')
    ];


    const firstContact = [
        require('./../../assets/slides/first-contact/1.jpg'),
        require('./../../assets/slides/first-contact/2.jpg'),
        require('./../../assets/slides/first-contact/3.jpg'),
        require('./../../assets/slides/first-contact/4.jpg')
    ];

    const indoorSkydiving = [
        require('./../../assets/slides/indoor-skydiving/1.jpg'),
        require('./../../assets/slides/indoor-skydiving/2.jpg'),
        require('./../../assets/slides/indoor-skydiving/3.jpg'),
    ];

    const lunchDinner = [
        require('./../../assets/slides/lunch-dinner/1.jpg'),
        require('./../../assets/slides/lunch-dinner/2.jpg'),
        require('./../../assets/slides/lunch-dinner/3.jpg'),
        require('./../../assets/slides/lunch-dinner/4.jpg')
    ];

    const stellarAstronomy = [
        require('./../../assets/slides/stellar-astronomy/1.jpg'),
        require('./../../assets/slides/stellar-astronomy/2.jpg'),
        require('./../../assets/slides/stellar-astronomy/3.jpg'),
    ];

    const theOracle = [
        require('./../../assets/slides/the-oracle/1.jpg'),
        require('./../../assets/slides/the-oracle/2.jpg'),
        require('./../../assets/slides/the-oracle/3.jpg'),
        require('./../../assets/slides/the-oracle/4.jpg')
    ];

    const venusianInfinityPool = [
        require('./../../assets/slides/venusian-infinity-pool/1.jpg'),
        require('./../../assets/slides/venusian-infinity-pool/2.jpg'),
        require('./../../assets/slides/venusian-infinity-pool/3.jpg'),
        require('./../../assets/slides/venusian-infinity-pool/4.jpg')
    ];


    const divStyle = {
        width: '400px',
        height: '400px',
        backgroundColor: '#000000'
    }

    const imageStyle = {
        height: '100%',
        width: 'auto',
        justifySelf: 'center'
    }

    return (
        <div>

            <div className='accordion my-0 my-lg-5 pb-0 pb-lg-5 full-width' id='more-services'>
                <div className='accordion-item'>
                    <h2 className='accordion-header' id='heading-Explore-the-moon'>
                        <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#collapse-Explore-the-moon' aria-expanded='false' aria-controls='collapse-Explore-the-moon'>
                            <img className='accordion-img' src={require('./../../assets/slides/exploring-the-moon/1.jpg')} alt={'exploring-moon'} />
                            <h3 className='mobile-fix-accordion'>Exploring the moon</h3>
                        </button>
                    </h2>
                    <div id='collapse-Explore-the-moon' className='accordion-collapse collapse' aria-labelledby='heading-Explore-the-moon' data-bs-parent='#more-services'>
                        <div className='accordion-body position-relative'>
                            <div className='row overflow-hidden slide-container'>
                                <div className='col-sm-12 col-md-12 col-lg-6 d-flex flex-wrap justify-content-center justify-content-lg-start'>
                                    <div className='vignette-accordion'></div>
                                    <Carousel divStyle={divStyle} className='test' delay={6000} mode={'fade'} >
                                        {exploringTheMoon.map((url, index) => (
                                            <div key={index} style={imageStyle}>
                                                <img
                                                    src={url}
                                                    style={{ width: '100%', height: 'auto' }}
                                                    alt='slide-1'
                                                    className='rounded-50'
                                                />
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-6 my-5 my-lg-0 overflow-hidden pb-5'>
                                    <h3>Let's explore together</h3>
                                    <div className='content-text'>
                                        <div className='my-4'>
                                            Exploring the moon is a thrilling adventure beyond Earth&#39;s atmosphere. As you step onto the lunar
                                            surface, you&#39;ll be awe-struck by the vastness of the crater-filled landscape, the stark contrast
                                            between light and shadow, and the absence of atmosphere and sound. You&#39;ll gaze at the Earth from
                                            a perspective unlike any other, with its blue and white hues shining brightly in the blackness of space.
                                        </div>
                                        <div className='my-4'>
                                            Prepare to be immersed in an otherworldly experience as you embark on this lunar expedition,
                                            unlocking the secrets of our celestial neighbor.
                                        </div>
                                        <div className='my-5'>
                                            <a href='#!' className='btn action-button position-absolute'>Find more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='accordion-item'>
                    <h2 className='accordion-header' id='heading-First-contact'>
                        <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#collapse-First-contact' aria-expanded='false' aria-controls='collapse-First-contact'>
                            <img className='accordion-img' src={require('./../../assets/slides/first-contact/1.jpg')} alt={'First-contact'} />
                            <h3 className='mobile-fix-accordion'>First contact</h3>
                        </button>
                    </h2>
                    <div id='collapse-First-contact' className='accordion-collapse collapse' aria-labelledby='heading-First-contact' data-bs-parent='#more-services'>
                        <div className='accordion-body position-relative'>
                            <div className='row overflow-hidden slide-container'>
                                <div className='col-sm-12 col-md-12 col-lg-6 d-flex flex-wrap justify-content-center justify-content-lg-start'>
                                    <div className='vignette-accordion'></div>
                                    <Carousel divStyle={divStyle} delay={6000} mode={'fade'} >
                                        {firstContact.map((url, index) => (
                                            <div key={index} style={imageStyle}>
                                                <img
                                                    src={url}
                                                    style={{ width: '100%', height: 'auto' }}
                                                    alt='slide-1'
                                                    className='rounded-50'
                                                />
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-6 my-5 my-lg-0 overflow-hidden pb-5'>
                                    <h3>Let's explore together</h3>
                                    <div className='content-text'>
                                        <div className='my-4'>
                                            &quot;First Contact&quot; is an immersive decision-making gameplay experience that puts you in the role of a
                                            diplomat representing humanity during humanity&#39;s first encounter with an alien civilization. As you
                                            embark on this historic mission, you&#39;ll be faced with a myriad of choices that will shape the outcome
                                            of the encounter and potentially alter the course of interstellar relations.
                                        </div>
                                        <div className='my-4'>
                                            You&#39;ll engage in deep
                                            conversations with alien beings, negotiate treaties, and navigate cultural differences, all while
                                            making critical decisions that impact the fate of both species.
                                        </div>
                                        <div className='my-5'>
                                            <a href='#!' className='btn action-button position-absolute'>Find more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='accordion-item'>
                    <h2 className='accordion-header' id='heading-Indoor-skydiving'>
                        <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#collapse-Indoor-skydiving' aria-expanded='false' aria-controls='collapse-Indoor-skydiving'>
                            <img className='accordion-img' src={require('./../../assets/slides/indoor-skydiving/1.jpg')} alt={'indoor-skydiving'} />
                            <h3 className='mobile-fix-accordion'>Indoor skydiving</h3>
                        </button>
                    </h2>
                    <div id='collapse-Indoor-skydiving' className='accordion-collapse collapse' aria-labelledby='heading-Indoor-skydiving' data-bs-parent='#more-services'>
                        <div className='accordion-body position-relative'>
                            <div className='row overflow-hidden slide-container'>
                                <div className='col-sm-12 col-md-12 col-lg-6 d-flex flex-wrap justify-content-center justify-content-lg-start'>
                                    <div className='vignette-accordion'></div>
                                    <Carousel divStyle={divStyle} delay={6000} mode={'fade'} >
                                        {indoorSkydiving.map((url, index) => (
                                            <div key={index} style={imageStyle}>
                                                <img
                                                    src={url}
                                                    style={{ width: '100%', height: 'auto' }}
                                                    alt='slide-1'
                                                    className='rounded-50'
                                                />
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-6 my-5 my-lg-0 overflow-hidden pb-5'>
                                    <h3>Let's explore together</h3>
                                    <div className='content-text'>
                                        <div className='my-4'>
                                            Indoor skydiving is a thrilling and exhilarating experience that simulates the sensation of freefalling
                                            from an airplane, but in the safety of an enclosed vertical wind tunnel. Participants can defy gravity
                                            and experience the sensation of flight as they float on a cushion of air, performing acrobatic
                                            maneuvers and enjoying the adrenaline rush of skydiving without the need for an airplane or
                                            parachute.
                                        </div>
                                        <div className='my-4'>
                                            With trained instructors and state-of-the-art technology, indoor skydiving offers a safe
                                            and accessible way for people of all ages and abilities to experience the thrill of skydiving in a
                                            controlled environment, making it a popular and exciting adventure activity for thrill-seekers and
                                            aviation enthusiasts alike.
                                        </div>
                                        <div className='my-5'>
                                            <a href='#!' className='btn action-button position-absolute'>Find more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='accordion-item'>
                    <h2 className='accordion-header' id='heading-Alien-forest-walk'>
                        <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#collapse-Alien-forest-walk' aria-expanded='false' aria-controls='collapse-Alien-forest-walk'>
                            <img className='accordion-img' src={require('./../../assets/slides/alien-forest-walk/1.jpg')} alt={'Alien-forest-walk'} />
                            <h3 className='mobile-fix-accordion'>Alien forest walk</h3>
                        </button>
                    </h2>
                    <div id='collapse-Alien-forest-walk' className='accordion-collapse collapse' aria-labelledby='heading-Alien-forest-walk' data-bs-parent='#more-services'>
                        <div className='accordion-body position-relative'>
                            <div className='row overflow-hidden slide-container'>
                                <div className='col-sm-12 col-md-12 col-lg-6 d-flex flex-wrap justify-content-center justify-content-lg-start'>
                                    <div className='vignette-accordion'></div>
                                    <Carousel divStyle={divStyle} delay={6000} mode={'fade'} >
                                        {alienForest.map((url, index) => (
                                            <div key={index} style={imageStyle}>
                                                <img
                                                    src={url}
                                                    style={{ width: '100%', height: 'auto' }}
                                                    alt='slide-1'
                                                    className='rounded-50'
                                                />
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-6 my-5 my-lg-0 overflow-hidden pb-5'>
                                    <h3>Let's explore together</h3>
                                    <div className='content-text'>
                                        <div className='my-4'>
                                            &quot;Alien forest walk&quot; is an immersive experience through an otherworldly woodland. The forest is like
                                            nothing on Earth, with bizarre flora and fauna that seem to defy the laws of nature. As you stroll
                                            through the misty pathways, you&#39;ll encounter glowing mushrooms, peculiar plants with
                                            bioluminescent flowers, and creatures with iridescent scales that shimmer in the eerie light.
                                        </div>
                                        <div className='my-4'>
                                            The air is
                                            filled with an otherworldly hum, and you can&#39;t shake off the feeling that you&#39;ve stepped into a realm
                                            from another planet. Get ready for an unforgettable adventure in this mysterious and enchanting
                                            &quot;alien&quot; forest.                                            </div>
                                        <div className='my-5'>
                                            <a href='#!' className='btn action-button position-absolute'>Find more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='accordion-item'>
                    <h2 className='accordion-header' id='heading-Lunch-dinner'>
                        <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#collapse-Lunch-dinner' aria-expanded='false' aria-controls='collapse-Lunch-dinner'>
                            <img className='accordion-img' src={require('./../../assets/slides/lunch-dinner/1.jpg')} alt={'Lunch-dinner'} />
                            <h3 className='mobile-fix-accordion'>Lunch / Dinner</h3>
                        </button>
                    </h2>
                    <div id='collapse-Lunch-dinner' className='accordion-collapse collapse' aria-labelledby='heading-Lunch-dinner' data-bs-parent='#more-services'>
                        <div className='accordion-body position-relative'>
                            <div className='row overflow-hidden slide-container'>
                                <div className='col-sm-12 col-md-12 col-lg-6 d-flex flex-wrap justify-content-center justify-content-lg-start'>
                                    <div className='vignette-accordion'></div>
                                    <Carousel divStyle={divStyle} delay={6000} mode={'fade'} >
                                        {lunchDinner.map((url, index) => (
                                            <div key={index} style={imageStyle}>
                                                <img
                                                    src={url}
                                                    style={{ width: '100%', height: 'auto' }}
                                                    alt='slide-1'
                                                    className='rounded-50'
                                                />
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-6 my-5 my-lg-0 overflow-hidden pb-5'>
                                    <h3>Let's explore together</h3>
                                    <div className='content-text'>
                                        <div className='my-4'>
                                            Experience a dinner in the Orbital Colony of Xenos, where gastronomy meets futuristic living in outer
                                            space. Imagine dining in a cutting-edge space station perched on an alien planet’s orbit, with
                                            panoramic views of the stars and planets as your backdrop.
                                        </div>
                                        <div className='my-4'>
                                            The Orbital Colony of Xenos offers an
                                            unparalleled dining experience, where gourmet cuisine is elevated to new heights, state-of-the-art
                                            technology and a sense of wonder that only space can provide.
                                        </div>
                                        <div className='my-5'>
                                            <a href='#!' className='btn action-button position-absolute'>Find more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='accordion-item'>
                    <h2 className='accordion-header' id='heading-cyborg'>
                        <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#collapse-cyborg' aria-expanded='false' aria-controls='collapse-cyborg'>
                            <img className='accordion-img' src={require('./../../assets/slides/cyborg/1.jpg')} alt={'Starship-defense'} />
                            <h3 className='mobile-fix-accordion'>Cyborg</h3>
                        </button>
                    </h2>
                    <div id='collapse-cyborg' className='accordion-collapse collapse' aria-labelledby='heading-cyborg' data-bs-parent='#more-services'>
                        <div className='accordion-body position-relative'>
                            <div className='row overflow-hidden slide-container'>
                                <div className='col-sm-12 col-md-12 col-lg-6 d-flex flex-wrap justify-content-center justify-content-lg-start'>
                                    <div className='vignette-accordion'></div>
                                    <Carousel divStyle={divStyle} delay={6000} mode={'fade'} >
                                        {cyborg.map((url, index) => (
                                            <div key={index} style={imageStyle}>
                                                <img
                                                    src={url}
                                                    style={{ width: '100%', height: 'auto' }}
                                                    alt='slide-1'
                                                    className='rounded-50'
                                                />
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-6 my-5 my-lg-0 overflow-hidden pb-5'>
                                    <h3>Let's explore together</h3>
                                    <div className='content-text'>
                                        <div className='my-4'>
                                            Cyborg - The New Civilization is a riveting science fiction tale that explores a world where biological
                                            entities and technology have merged to create a groundbreaking civilization.
                                        </div>
                                        <div className='my-4'>
                                            With vivid world-building, thought-provoking themes, and engaging characters, Cyborg - The New
                                            Civilization is a compelling story that challenges our perceptions of what it means to be a superior
                                            technological entity. Join the journey as this new civilization navigates uncharted territory and faces
                                            the consequences of pushing the boundaries of biological existence.
                                        </div>
                                        <div className='my-5'>
                                            <a href='#!' className='btn action-button position-absolute'>Find more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='accordion-item'>
                    <h2 className='accordion-header' id='heading-Stellar-astronomy'>
                        <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#collapse-Stellar-astronomy' aria-expanded='false' aria-controls='collapse-Stellar-astronomy'>
                            <img className='accordion-img' src={require('./../../assets/slides/stellar-astronomy/1.jpg')} alt={'Stellar-astronomy'} />
                            <h3 className='mobile-fix-accordion'>Stellar observatory</h3>
                        </button>
                    </h2>
                    <div id='collapse-Stellar-astronomy' className='accordion-collapse collapse' aria-labelledby='heading-Stellar-astronomy' data-bs-parent='#more-services'>
                        <div className='accordion-body position-relative'>
                            <div className='row overflow-hidden slide-container'>
                                <div className='col-sm-12 col-md-12 col-lg-6 d-flex flex-wrap justify-content-center justify-content-lg-start'>
                                    <div className='vignette-accordion'></div>
                                    <Carousel divStyle={divStyle} delay={6000} mode={'fade'} >
                                        {stellarAstronomy.map((url, index) => (
                                            <div key={index} style={imageStyle}>
                                                <img
                                                    src={url}
                                                    style={{ width: '100%', height: 'auto' }}
                                                    alt='slide-1'
                                                    className='rounded-50'
                                                />
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-6 my-5 my-lg-0 overflow-hidden pb-5'>
                                    <h3>Let's explore together</h3>
                                    <div className='content-text'>
                                        <div className='my-4'>
                                            Equipped with cutting-edge telescopes and advanced imaging technology, it offers an unrivaled
                                            vantage point for observing the wonders of the night sky. Researchers and astronomers from around
                                            the world flock to this observatory to study distant stars, galaxies, and cosmic phenomena, unlocking
                                            the mysteries of the universe.
                                        </div>
                                        <div className='my-4'>
                                            With its pristine skies, advanced instruments, and dedicated team of
                                            scientists, the stellar astronomical observatory is a beacon of discovery and a gateway to unraveling
                                            the secrets of the cosmos.
                                        </div>
                                        <div className='my-5'>
                                            <a href='#!' className='btn action-button position-absolute'>Find more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='accordion-item'>
                    <h2 className='accordion-header' id='heading-The-oracle'>
                        <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#collapse-The-oracle' aria-expanded='false' aria-controls='collapse-Stellar-astronomy'>
                            <img className='accordion-img' src={require('./../../assets/slides/the-oracle/1.jpg')} alt={'Stellar-astronomy'} />
                            <h3 className='mobile-fix-accordion'>The Oracle of Atlantis</h3>
                        </button>
                    </h2>
                    <div id='collapse-The-oracle' className='accordion-collapse collapse' aria-labelledby='heading-The-oracle' data-bs-parent='#more-services'>
                        <div className='accordion-body position-relative'>
                            <div className='row overflow-hidden slide-container'>
                                <div className='col-sm-12 col-md-12 col-lg-6 d-flex flex-wrap justify-content-center justify-content-lg-start'>
                                    <div className='vignette-accordion'></div>
                                    <Carousel divStyle={divStyle} delay={6000} mode={'fade'} >
                                        {theOracle.map((url, index) => (
                                            <div key={index} style={imageStyle}>
                                                <img
                                                    src={url}
                                                    style={{ width: '100%', height: 'auto' }}
                                                    alt='slide-1'
                                                    className='rounded-50'
                                                />
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-6 my-5 my-lg-0 overflow-hidden pb-5'>
                                    <h3>Let's explore together</h3>
                                    <div className='content-text'>
                                        <div className='my-4'>
                                            &quot;The Oracle of Atlantis&quot; is a groundbreaking psychological game that utilizes artificial intelligence
                                            (A.I.) to create a unique and immersive experience of self-exploration. Set in the mythical lost city of
                                            Atlantis, the game invites players on a journey of introspection and self-discovery, guided by an
                                            advanced A.I. system known as the Oracle.
                                        </div>
                                        <div className='my-4'>
                                            Through interactive dialogues, thought-provoking questions, and personalized scenarios, the Oracle
                                            delves deep into the player&#39;s psyche, uncovering hidden emotions, beliefs, and motivations. The A.I.
                                            system adapts to the player&#39;s responses, dynamically tailoring the experience to each individual,
                                            providing insights, challenges, and guidance based on the player&#39;s unique personality traits and
                                            behaviors. Be ready to discover your hidden desires!
                                        </div>
                                        <div className='my-5'>
                                            <a href='#!' className='btn action-button position-absolute'>Find more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='accordion-item'>
                    <h2 className='accordion-header' id='heading-Venusian-infinity-pool'>
                        <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#collapse-Venusian-infinity-pool' aria-expanded='false' aria-controls='collapse-Venusian-infinity-pool'>
                            <img className='accordion-img' src={require('./../../assets/slides/venusian-infinity-pool/1.jpg')} alt={'Venusian-infinity-pool'} />
                            <h3 className='mobile-fix-accordion'>Venusian infinity pool</h3>
                        </button>
                    </h2>
                    <div id='collapse-Venusian-infinity-pool' className='accordion-collapse collapse' aria-labelledby='heading-Venusian-infinity-pool' data-bs-parent='#more-services'>
                        <div className='accordion-body position-relative'>
                            <div className='row overflow-hidden slide-container'>
                                <div className='col-sm-12 col-md-12 col-lg-6 d-flex flex-wrap justify-content-center justify-content-lg-start'>
                                    <div className='vignette-accordion'></div>
                                    <Carousel divStyle={divStyle} delay={6000} mode={'fade'} >
                                        {venusianInfinityPool.map((url, index) => (
                                            <div key={index} style={imageStyle}>
                                                <img
                                                    src={url}
                                                    style={{ width: '100%', height: 'auto' }}
                                                    alt='slide-1'
                                                    className='rounded-50'
                                                />
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-6 my-5 my-lg-0 overflow-hidden pb-5'>
                                    <h3>Let's explore together</h3>
                                    <div className='content-text'>
                                        <div className='my-4'>
                                            The Venusian Infinity Pool is a mesmerizing and otherworldly oasis that transports visitors to a
                                            tropical paradise on the planet Venus. Imagine a stunning pool of crystal-clear water, surrounded by
                                            lush vegetation and unique rock formations, all under a sky painted with hues of pink and orange.
                                            The pool seemingly extends infinitely towards the horizon, creating a sense of endless serenity and
                                            tranquility.
                                        </div>
                                        <div className='my-4'>
                                            The exotic and alien landscape of Venus, with its extreme temperatures and dense atmosphere, adds
                                            to the sense of awe and wonder, making the Venusian Infinity Pool a truly unique and unforgettable
                                            destination for those seeking a one-of-a-kind aquatic escape.
                                        </div>
                                        <div className='my-5'>
                                            <a href='#!' className='btn action-button position-absolute'>Find more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Accordion