import React from 'react'
import Accordion from '../accordion/Accordion';
import Carousel from 'fade-carousel';
import './about.css';


function About() {

    const lostOnMars = [
        require('./../../assets/slides/lost-on-mars/1.jpg'),
        require('./../../assets/slides/lost-on-mars/2.jpg'),
        require('./../../assets/slides/lost-on-mars/3.jpg'),
        require('./../../assets/slides/lost-on-mars/4.jpg')
    ];

    const starshipDefense = [
        require('./../../assets/slides/starship-defense/1.jpg'),
        require('./../../assets/slides/starship-defense/2.jpg'),
        require('./../../assets/slides/starship-defense/3.jpg'),
        require('./../../assets/slides/starship-defense/4.jpg')
    ];

    const divStyle = {
        height: '100%',
        minHeight: '390px',
        maxHeight: '500px',
        width: '100%',
        maxWidth: '500px',
        backgroundColor: '#000000'
    }

    const imageStyle = {
        height: '100%',
        width: 'auto',
        justifySelf: 'center'
    }

    return (
        <div className='my-lg-5'>
            <div className='row my-5'>
                <div className='col-sm-12 col-md-12 col-lg-6 order-2 order-lg-1 d-flex flex-wrap justify-content-center justify-content-lg-start md-fix overflow-hidden slide-container'>
                    <div className='vignette'></div>
                    <Carousel divStyle={divStyle} delay={6000} mode={"fade"}>
                        {starshipDefense.map((url, index) => (
                            <div key={index} style={imageStyle}>
                                <img
                                    src={url}
                                    style={{ width: "100%", height: "auto" }}
                                    alt="slide-1"
                                    className='rounded-50'
                                />
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-6 mb-5 pb-5 px-lg-5 overflow-hidden order-1 order-lg-2'>
                    <h1 className='bold mb-4' data-aos='fade-left' data-aos-delay='100'>Starship Defense</h1>
                    <div className='content-text my-4' data-aos='fade-left'>
                        „Starship Defense" offers an unparalleled immersive gameplay experience that transports
                        players into an epic intergalactic battle. As a skilled starship commander, you'll take
                        the helm of a cutting- edge spacecraft, navigating through asteroid fields, nebulae, and
                        wormholes in a quest to protect your home planet from invading alien forces. You'll command
                        your crew, strategize your defenses, and engage in heart-pounding space combat, utilizing
                        advanced weapons and technology to repel enemy attacks.
                    </div>
                    <div className='content-text my-4' data-aos='fade-left'>
                        The stunning graphics and realistic sound effects will draw you into the vastness of space,
                        as you witness dazzling cosmic phenomena and encounter alien civilizations with unique
                        abilities and tactics. Get ready to embark on an interstellar adventure like no other!
                    </div>
                    <a href='#!' className='btn action-button my-4'>Find more</a>
                </div>
            </div>

            <div className='row my-5'>
                <div className='col-sm-12 col-md-12 col-lg-6 mb-5 pb-5 px-lg-5 overflow-hidden'>
                    <h1 className='bold mb-4' data-aos='fade-right'>Lost on Mars</h1>
                    <div className='content-text my-4' data-aos='fade-right'>
                        "Lost on Mars" takes you on an extraordinary journey to the red planet. As you explore the
                        desolate Martian landscape, you'll encounter vast sand dunes stretching into the horizon,
                        towering volcanoes that loom in the distance, and a sky painted in hues of ochre and copper.
                        The eerie silence is broken only by the sound of your own footsteps crunching on the rusty
                        terrain. You'll navigate through mysterious caves and come across strange rock formations
                        that seem to defy gravity.
                    </div>
                    <div className='content-text my-4' data-aos='fade-right'>
                        With each step, you'll feel a sense of isolation and wonder, as if you've been transported
                        to another world altogether. Prepare to be captivated by the otherworldly beauty of being
                        "Lost on Mars."
                    </div>
                    <a href='#!' className='btn action-button my-4'>Find more</a>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-6 d-flex flex-wrap justify-content-center justify-content-lg-end md-fix overflow-hidden slide-container'>
                    <div className='vignette'></div>
                    <Carousel divStyle={divStyle} delay={6000} mode={"fade"}>
                        {lostOnMars.map((url, index) => (
                            <div key={index} style={imageStyle}>
                                <img
                                    src={url}
                                    style={{ width: "100%", height: "auto" }}
                                    alt="slide-1"
                                    className='rounded-50'
                                />
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>

            <div className='row my-5'>
                <div className='col-sm-12 col-md-12 col-lg-12 px-lg-5'>
                    <h1 className='bold'>More adventures</h1>
                    <Accordion />
                </div>
            </div>
        </div>
    )
}

export default About