import './contact.css';
import React from 'react'

function Contact() {
    return (
        <div className='my-5 text-center'>
            <h1 className='bold mt-5'>Contact us</h1>
            <div className='my-4 content-text'>
                Send us your email address and we will contact you as soon as possible!
            </div>
            <div className='row contact-form my-5'>
                <div className='col-sm-9 col-md-7 col-lg-9'>
                    <div className='form-group'>
                        <input type='email' className='form-control' autoComplete='off' id='email__input' aria-describedby='email__input' placeholder='Input Email Address' />
                    </div>
                </div>
                <div className='col-sm-3 col-md-5 col-lg-3 text-end contact-button-wrapper'>
                    <button type='submit' className='contact-button'>Send Address</button>
                </div>

            </div>

        </div >
    )
}

export default Contact