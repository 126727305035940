import './news.css';
import React from 'react'

function News() {
    return (
        <div>
            <div className='my-5'>
                <div className='mb-5'>
                    <h1 className='bold'>Our latest News</h1>
                </div>
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-6 py-2' data-aos='fade-in'>
                        <a href='#!'>
                            <div className='news-hot'>
                                <div className='news-backdrop'>
                                    <h2>Travel gate 1.0 gets shut down</h2>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-6' data-aos='fade-in' data-aos-delay='100'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 py-2'>
                                <a href='#!'>
                                    <div className='news-2'>
                                        <div className='news-backdrop'>
                                            <h2>New personal spaceship</h2>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-6 py-2' data-aos='fade-in' data-aos-delay='200'>
                                <a href='#!'>
                                    <div className='news-3'>
                                        <div className='news-backdrop'>
                                            <h2>Travel gate 2.0</h2>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-6 py-2' data-aos='fade-in' data-aos-delay='300'>
                                <a href='#!'>
                                    <div className='news-4'>
                                        <div className='news-backdrop'>
                                            <h2>For sale on Mars</h2>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default News