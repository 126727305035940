import './partners.css';
import React from 'react'

function Partners() {
    return (
        <div className='partners-wrapper pb-5 mb-lg-0'>
            <div className='w-100 text-center'>
                <h1 className='bold my-3 my-lg-5'>Our Partners</h1>
                <div className='container'>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-4 col-md-4 col-lg-2 my-3 my-lg-0'>
                            <img className='partners-logo' draggable='false' src={require('./../../assets/images/partners-logos/nasa.png')} alt={'partner-1'} />
                        </div>
                        <div className='col-4 col-md-4 col-lg-2 my-3 my-lg-0'>
                            <img className='partners-logo' draggable='false' src={require('./../../assets/images/partners-logos/xbox.png')} alt={'partner-2'} />
                        </div>
                        <div className='col-4 col-md-4 col-lg-2 my-3 my-lg-0'>
                            <img className='partners-logo' draggable='false' src={require('./../../assets/images/partners-logos/google.png')} alt={'partner-3'} />
                        </div>
                        <div className='col-4 col-md-4 col-lg-2 my-3 my-lg-0'>
                            <img className='partners-logo' draggable='false' src={require('./../../assets/images/partners-logos/apple.png')} alt={'partner-4'} />
                        </div>
                        <div className='col-4 col-md-4 col-lg-2 my-3 my-lg-0'>
                            <img className='partners-logo' draggable='false' src={require('./../../assets/images/partners-logos/wii.png')} alt={'partner-5'} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Partners